import React, { useEffect, useState } from 'react';

const Explore = () => {
  const [qrmyLinks, setQrmyLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAction, setSelectedAction] = useState("All");
  const [sortOrder, setSortOrder] = useState("recent");

  // Fetch recently activated links
  useEffect(() => {
    fetch('https://d1worker.sapienssimple.workers.dev/recent-activated-links')
      .then(response => response.json())
      .then(data => {
        setQrmyLinks(data.links);
      });
  }, []);

  // Search handler
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Action filter handler
  const handleActionFilter = (action) => {
    setSelectedAction(action);
  };

  // Sorting handler
  const handleSort = (order) => {
    setSortOrder(order);
  };

  // Filter by search query and action
  const filteredLinks = qrmyLinks
    .filter(link => link.link_id.includes(searchQuery))
    .filter(link => selectedAction === "All" || link.action === selectedAction);

  // Sort by activation date
  const sortedLinks = filteredLinks.sort((a, b) => {
    return sortOrder === "recent"
      ? new Date(b.activatedAt) - new Date(a.activatedAt)
      : new Date(a.activatedAt) - new Date(b.activatedAt);
  });

  return (
    <div className="explore-page">
      <div className="filters">
        <input
          type="text"
          placeholder="Search by Link ID"
          value={searchQuery}
          onChange={handleSearch}
        />
        
        <select onChange={(e) => handleActionFilter(e.target.value)}>
          <option value="All">All</option>
          <option value="PhoneNumber">Phone Number</option>
          <option value="TextToRead">Text</option>
          <option value="InstagramID">Instagram</option>
          <option value="Link">Link</option>
        </select>
        
        <select onChange={(e) => handleSort(e.target.value)}>
          <option value="recent">Most Recent</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>

      <div className="qrmy-cards-container">
        {sortedLinks.length > 0 ? (
          sortedLinks.map((link, index) => (
            <div key={index} className="qrmy-card">
              <h3>Action: {link.action}</h3>
              <p>Data: {link.user_data}</p>
              <a href={`https://qrmy.ir/${link.link_id}`} target="_blank" rel="noopener noreferrer">Open Link</a>
              <p>Activated: {new Date(link.activatedAt).toLocaleString()}</p>
            </div>
          ))
        ) : (
          <p>No links found.</p>
        )}
      </div>
    </div>
  );
};

export default Explore;
