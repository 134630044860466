const texts = {
      homePersian: 'کیوآرمی چیه!؟',
      homeEnglish: 'What is Qrmy?',
  // Add more translations here as needed
    actions: {
      publicLabel: {
        farsi: "عمومی",
        english: "Public",
      },
      privateLabel: {
        farsi: "خصوصی",
        english: "Private",
      },
      publicLabel: {
        farsi: "عمومی",
        english: "Public",
      },
      activationDescription: {
        farsi: "لطفا یکی از اقدامات زیر را انتخاب کنید و اطلاعات مربوطه را وارد کنید.",
        english: "Please select an action below and enter the corresponding details."
      },
      PhoneNumber: {
        farsi: 'تنظیم شماره تلفن روی کیوآرمی',
        english: 'Please enter your desired mobile number.',
      },
      TextToRead: {
        farsi: 'تنظیم یادداشت روی کیوآرمی',
        english: 'Enter the note or text you want to be read.',
      },
      InstagramID: {
        farsi: 'تنظیم اینستاگرام روی کیوآرمی',
        english: 'Enter your Instagram username.',
      },
      Link: {
        farsi: 'تنظیم لینک روی کیوآرمی',
        english: 'Enter your desired Link.',
      },
    },
    placeholders: {
      PhoneNumber: {
        farsi: '09123456789',
        english: '09123456789',
      },
      TextToRead: {
        farsi: 'یادداشت خود را وارد کنید',
        english: 'Enter your note',
      },
      InstagramID: {
        farsi: 'Instagram.com/username',
        english: '@username',
      },
      Link: {
        farsi: 'Example.com or t.me/username',
        english: 'Enter Link',
      },
    },
    toggle: {
      public: {
        farsi: 'عـمـومـی',
        english: 'Public',
      },
      private: {
        farsi: 'خصوصی',
        english: 'Private',
      },
      toggleExplanationPublic: {
        farsi: 'در اکسپلور نمایش داده خواهد شد.',
        english: 'Will be displayed in the explore.',
      },
      toggleExplanationPrivate: {
        farsi: 'در اکسپلور نمایش داده نمیشود.',
        english: 'Will not be displayed in the explore.',
      },
    },
    validationMessages: {
      valid: {
        farsi: 'شماره معتبر است.',
        english: 'The number is valid',
      },
      invalid: {
        farsi: 'شماره معتبر نیست.',
        english: 'The number is invalid',
      },
    },
    activationButton: {
      farsi: 'آماده فعالسازی',
      english: 'Activate Qrmy',
    },
    captchaText: {
      farsi: '',
      english: '',
    },
    activationSuccess: {
      farsi: 'در حال هدایت به لینک فعال شده...',
      english: 'Link activated successfully!',
    },
    activationFailure: {
      farsi: 'دوباره بزن',
      english: 'Activation failed.',
    },
    errorMessage: {
      farsi: 'دوباره فعالش کن!',
      english: 'Error during activation.',
    },
    
    // Add more translations as needed
  };
  
  export default texts;
  