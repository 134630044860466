// Footer.js
// Updated on 2024-09-28 at 12:50 PM
// This component represents the footer section of the landing page.
// It displays credits or any additional information, enhancing the user experience.

import React from 'react';
import './Footer.css'; // Create a CSS file for Footer styles

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <img src="/credits.svg" alt="Credits" className="credits-logo" />
                <p className="footer-text">همه‌ی حقوق برای شرکت فناوران توسعه تجربه ساده محفوظ است.</p>
            </div>
        </footer>
    );
};

export default Footer;
