import React, { useEffect, useState, useRef } from 'react';

const Captcha = ({ onSuccess = () => {}, onError = () => {} }) => {
    const [siteKey, setSiteKey] = useState('');
    const isCaptchaLoaded = useRef(false); // To track if the CAPTCHA has been loaded
    const captchaContainerRef = useRef(null); // Ref for the CAPTCHA container

    // Fetch CAPTCHA site key from the D1 Worker API
    useEffect(() => {
        const fetchSiteKey = async () => {
            try {
                const response = await fetch('https://d1worker.sapienssimple.workers.dev/get-site-key');
                if (response.ok) {
                    const data = await response.json();
                    setSiteKey(data.siteKey);
                } else {
                    console.error('Failed to fetch CAPTCHA site key:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching CAPTCHA site key:', error);
            }
        };

        fetchSiteKey();
    }, []); // Run once when the component mounts

    // Function to load the Turnstile CAPTCHA script
    const loadCaptcha = () => {
        if (isCaptchaLoaded.current) return; // Prevent loading multiple times

        console.log('Loading Turnstile CAPTCHA...'); // Debug log

        const script = document.createElement('script');
        script.src = `https://challenges.cloudflare.com/turnstile/v0/api.js`;
        script.async = true;

        script.onload = () => {
            console.log('Turnstile script loaded successfully.'); // Log on script load
            try {
                // Render the Turnstile CAPTCHA only if it hasn't been rendered yet
                if (!isCaptchaLoaded.current) {
                    window.turnstile.render(captchaContainerRef.current, { // Use ref to find the container
                        sitekey: siteKey,
                        callback: (token) => {
                            console.log('CAPTCHA solved, token:', token); // Log the token
                            onSuccess(token); // Pass the token to the onSuccess callback
                        },
                        'error-callback': onError,
                    });
                    isCaptchaLoaded.current = true; // Mark CAPTCHA as loaded
                    console.log('Turnstile CAPTCHA rendered.'); // Log after rendering CAPTCHA
                }
            } catch (error) {
                console.error('Error rendering Turnstile CAPTCHA:', error); // Log any error during rendering
                onError(error); // Call the error callback
            }
        };

        script.onerror = () => {
            console.error('Failed to load Turnstile CAPTCHA script.'); // Log script loading error
            onError(new Error('Failed to load CAPTCHA script.')); // Call error callback
        };

        document.body.appendChild(script);
    };

    // Load CAPTCHA when the siteKey is available
    useEffect(() => {
        if (siteKey) {
            loadCaptcha();
        } else {
            console.warn('No siteKey provided for CAPTCHA.'); // Log warning if no siteKey is given
        }
    }, [siteKey]); // Dependencies: re-run this effect when siteKey changes

    // Cleanup on component unmount
    useEffect(() => {
        return () => {
            if (isCaptchaLoaded.current) {
                // Reset the CAPTCHA container to prevent future errors
                const container = captchaContainerRef.current;
                if (container) {
                    container.innerHTML = ''; // Clear the container
                }
                isCaptchaLoaded.current = false; // Reset the loaded state
            }
        };
    }, []); // Run once on unmount

    return <div ref={captchaContainerRef} className="captcha-container"></div>; // Render the CAPTCHA container
};

export default Captcha;
