// ReadText.js
// Updated on 2024-10-14
// This component displays text content associated with a specific link ID using activation data.
// It handles loading states and error messages, and includes editing functionality with a CAPTCHA for verification.

import React, { useEffect, useState } from 'react';
import './ReadText.css';
import Captcha from './Captcha'; // Import the Captcha component

const ReadText = ({ linkId, activationData }) => {
    // State declarations
    const [textContent, setTextContent] = useState(''); // Holds the text content to read/edit
    const [error, setError] = useState(null); // Holds any error messages
    const [loading, setLoading] = useState(true); // Loading state
    const [language, setLanguage] = useState('fa'); // Language detection
    const [isEditing, setIsEditing] = useState(false); // Editing state
    const [remainingTime, setRemainingTime] = useState(900); // 15 minutes in seconds
    const [captchaToken, setCaptchaToken] = useState(''); // State for captcha token

    // Effect for setting text content and detecting language on activation data change
    useEffect(() => {
        console.log('Effect triggered: Checking activation data...');

        const detectLanguage = (text) => {
            const persianRegex = /[\u0600-\u06FF]/; // Regex for Persian characters
            return persianRegex.test(text) ? 'fa' : 'en'; // Returns 'fa' for Persian, 'en' for English
        };

        if (activationData) {
            const TextToRead = activationData.TextToRead || activationData; // Get text to read
            setTextContent(TextToRead); // Set the text content
            setLanguage(detectLanguage(TextToRead)); // Detect and set language
            console.log('Text content set:', TextToRead);
        } else {
            setError('No activation data available.'); // Set error if no data
            console.error('Error: No activation data available.');
        }

        setLoading(false); // Set loading to false after processing
    }, [activationData]);

    // Timer for remaining editing time
    useEffect(() => {
        let timer = null; // Timer variable
        if (isEditing && remainingTime > 0) {
            timer = setInterval(() => {
                setRemainingTime((prev) => prev - 1); // Decrease remaining time
            }, 1000);
        } else if (remainingTime <= 0) {
            setIsEditing(false); // Disable editing when time is up
            console.log('Editing time expired. Exiting editing mode.');
        }
        return () => clearInterval(timer); // Cleanup timer on unmount or when editing is disabled
    }, [isEditing, remainingTime]);

    // Handle click for editing
    const handleEditClick = () => {
        console.log('Edit button clicked. Entering editing mode.');
        setIsEditing(true); // Set editing mode
        setRemainingTime(900); // Reset remaining time to 15 minutes on entering edit mode
    };

    // Handle text change in the textarea
    const handleTextChange = (event) => {
        console.log('Text changed:', event.target.value); // Log the new text value
        setTextContent(event.target.value); // Update the text content
    };

    const handleSave = async () => {
        console.log('Save button clicked. Sending data to server...');
    
        // Validate that required values are present
        if (!textContent.trim() || !captchaToken) {
            console.error('Text content:', textContent);
            console.error('Captcha token:', captchaToken);
            setError('Text content or CAPTCHA is missing.'); // Inform user of missing data
            return; // Exit the function if data is missing
        }
    
        try {
            const response = await fetch(`https://d1worker.sapienssimple.workers.dev/edit/${linkId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    input: textContent,
                    token: captchaToken,
                }),
            });
    
            const result = await response.json();
    
            if (result.success) {
                alert(result.message);
                setIsEditing(false);
                console.log('Success:', result.message);
            } else {
                setError(result.message || 'Unknown error occurred.');
                console.error('Error from server:', result.message);
            }
        } catch (err) {
            console.error('Error saving changes:', err);
            setError('Failed to save changes. Please try again.');
        }
    };

    return (
        <div className="read-text-container">
            <div className="read-text-page">
                {loading && <div className="loading-message">در حال بارگذاری...</div>}
                {error && <div className="error-message">{error}</div>}
                {isEditing ? (
                    <textarea
                        className="editable-textarea"
                        value={textContent} // Controlled input
                        onChange={handleTextChange} // Handle text change
                        rows={5} // Adjust height as needed
                        lang={language} // Set language attribute
                    />
                ) : (
                    <div className="text-content" lang={language}>
                        {textContent} {/* Display text content */}
                    </div>
                )}
                {!isEditing && (
                    <div className="edit-controls">
                        <button className="edit-button" onClick={handleEditClick}>
                            ویرایش {/* Edit button */}
                        </button>
                        {remainingTime > 0 && (
                            <div className="time-left">
                                {Math.floor(remainingTime / 60)}:
                                {('0' + (remainingTime % 60)).slice(-2)} باقی مانده {/* Remaining time display */}
                            </div>
                        )}
                    </div>
                )}
                {isEditing && (
                    <>
                        <Captcha onChange={setCaptchaToken} /> {/* Captcha component to handle token */}
                        <button className="save-button" onClick={handleSave}>
                            ذخیره {/* Save button */}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default ReadText;
