import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import ActivationPage from './components/ActivationPage';
import ReadText from './components/ReadText';
import NotFound from './components/NotFound';
import OpeningAnimation from './components/OpeningAnimation';
import LandingPage from './components/LandingPage';
import Header from './components/Header';
import Footer from './components/Footer';
import Explore from './components/Explore'; // Import your Explore component
import './styles/fonts.css';


// Custom hook for fetching link data
const useFetchLinkData = (link_id, setLinkStatus) => {
    useEffect(() => {
        const fetchLinkData = async () => {
            if (!link_id) {
                console.log('No link_id provided');
                setLinkStatus({ status: 400, isActive: false });
                return;
            }

            console.log(`Fetching link data for link_id: ${link_id}`);
            try {
                const response = await fetch(`https://d1worker.sapienssimple.workers.dev/${link_id}`);
                
                if (!response.ok) {
                    console.log(`Error fetching link data: Status ${response.status}`);
                    setLinkStatus({ status: response.status, isActive: false });
                    return;
                }

                const data = await response.json();
                console.log('Link data fetched successfully:', data);
                setLinkStatus(data);
            } catch (error) {
                console.error('Error fetching link data:', error);
                setLinkStatus({ status: 500, isActive: false });
            }
        };

        fetchLinkData();
    }, [link_id, setLinkStatus]);
};

// Main App component
const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [linkStatus, setLinkStatus] = useState(null);
    const [linkId, setLinkId] = useState(null); // State to hold the link_id

    useEffect(() => {
        console.log('Opening animation started');
        const timer = setTimeout(() => {
            setIsLoading(false);
            console.log('Opening animation completed');
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Router>
            {isLoading ? (
                <OpeningAnimation />
            ) : (
                <>
                    <Header linkId={linkId} /> {/* Pass linkId to Header */}
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/explore" element={<Explore />} />
                        <Route path="/:link_id" element={<LinkHandler linkStatus={linkStatus} setLinkStatus={setLinkStatus} setLinkId={setLinkId} />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <Footer />
                </>
            )}
        </Router>
    );
};

// LinkHandler component
const LinkHandler = ({ linkStatus, setLinkStatus, setLinkId }) => {
    const { link_id } = useParams();
    setLinkId(link_id); // Set link_id in App's state
    useFetchLinkData(link_id, setLinkStatus);

    // Show loading until linkStatus is determined
    if (!linkStatus) {
        return <div className="loading-message">Loading...</div>; // Show loading state while fetching
    }

    const { status, action, user_data } = linkStatus;

    // Handling based on the linkStatus
    switch (status) {
        case 201: // Link is found but not activated
            return <ActivationPage link_id={link_id} onActivationComplete={() => setLinkStatus(null)} />;
        case 200: // Link is active
            switch (action) {
                case 'Link':
                    window.location.href = `https://${user_data}/`;
                    return null;
                case 'InstagramID':
                    window.location.href = `https://www.instagram.com/${user_data}/`;
                    return null;
                case 'PhoneNumber':
                    window.location.href = `tel:${user_data}`;
                    return null;
                case 'TextToRead':
                    return <ReadText link_id={link_id} activationData={user_data} />;
                default:
                    return <LandingPage />;
            }
        case 404: // Link not found
            return <NotFound />;
        case 500: // Internal server error
            return <div>Error: Server is unavailable. Please try again later.</div>;
        default:
            return <LandingPage />;
    }
};

export default App;
