// Header.js
// Updated on 2024-10-13
// This component represents the header section of the landing page.
// It includes the logo and navigation links, allowing users to navigate to the home page.
// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = ({ linkId }) => {
    return (
        <header className="landing-header">
            <nav className="navigation">
                {linkId && <p className="link-id">{linkId}</p>} {/* Display linkId if available */}
                <Link to="/" className="logo">
                    <img src="/qrmy.svg" alt="qrmy logo" />
                </Link>
            </nav>
        </header>
    );
};

export default Header;
