// LandingPage.js
// Updated on 2024-09-28 at 12:30 PM
// This component represents the landing page of the qrmy application.
// It showcases the purpose of the application and provides a clear introduction to its features.
// The page includes a header, a hero section with text content in Persian, a pricing section, and a call to action for purchasing qrmy packs.

import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
    // Function to scroll to the pricing section smoothly
    const scrollToPricing = () => {
        document.getElementById('pricing-section').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="landing-container">
            <section className="hero">
                <div className="text-placeholder">
                    <h2 className="persian-text">کیوآرمی چیه!؟</h2>
                    <p className="persian-text">
                        کیوآرمی، محصول نوآورانه‌ی استودیو ساده‌ست، شیوه‌ای نوین برای بهره‌برداری حداکثری از تکنولوژی کیو آر، اکنون در دسترس شما قرار گرفته است. 
                        کیوآرمی برچسب‌های کیو آر خام و غیرفعال است که هر یک قابلیت تنظیم برای ویژگی‌های مختلف را دارند. 
                        برای فعالسازی هر برچسب کیوآرمی، تنها کافیست آن را با دوربین موبایل خود اسکن کرده و با انتخاب قابلیت مورد نظر و لمس دکمه‌ی فعالسازی، 
                        کیوآرمی خود را به آسانی فعال کنید. 
                        پس از فعالسازی، برچسب برای همیشه به قابلیت انتخاب شده شما اختصاص می‌یابد.
                    </p>
                    {/* Scroll Down Arrow */}
                    <div className="scroll-arrow" onClick={scrollToPricing}>
                        <span>&#8595;</span> {/* Down arrow symbol */}
                    </div>
                </div>
            </section>

            {/* Pricing Section */}
            <section id="pricing-section" className="pricing-section">
                <h3>کیوآرمی چند!؟</h3>
                <table className="pricing-table">
                    <thead>
                        <tr>
                            <th>نوع خرید</th>
                            <th>حداکثر تعداد فروش</th>
                            <th>بسته</th>
                            <th>هر بسته حاوی</th>
                            <th>قیمت (تومان)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan="2">خرید شخصی</td>
                            <td rowSpan="2">۱۰ بسته</td>
                            <td>کوچک (3 cm)</td>
                            <td>۲۰ کیوآرمی</td>
                            <td>۷۰,۰۰۰</td>
                        </tr>
                        <tr>
                            <td>بزرگ (5 cm)</td>
                            <td>۲۰ کیوآرمی</td>
                            <td>۱۱۰,۰۰۰</td>
                        </tr>
                        <tr>
                            <td rowSpan="2">خرید سازمانی</td>
                            <td rowSpan="2">۲۰۰ بسته</td>
                            <td>کوچک (3 cm)</td>
                            <td>۲۰ کیوآرمی</td>
                            <td>۶۰,۰۰۰</td>
                        </tr>
                        <tr>
                            <td>بزرگ (5 cm)</td>
                            <td>۲۰ کیوآرمی</td>
                            <td>۹۵,۰۰۰</td>
                        </tr>
                        <tr>
                            <td>مشارکت در فروش</td>
                            <td>بیشتر از ۲۰۰ بسته</td>
                            <td colSpan="3">
                                <a href="tel:+989122709040" className="call-button">با واحد فروش کیوآرمی تماس بگیرید...</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>

            {/* Call to Action */}
            <section className="cta-section">
                <h3>کیوآرمی را از هایپرمارکت‌ها تهیه کنید!</h3>
            </section>
        </div>
    );
};

export default LandingPage;
