import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css'; // Import global CSS styles
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Create a root element using createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// Register the service worker with error handling
serviceWorkerRegistration.register({
    onUpdate: registration => {
        if (window.confirm("New version available! Do you want to refresh?")) {
            window.location.reload();
        }
    },
    onError: error => {
        console.error("Service Worker registration failed:", error);
    },
});
