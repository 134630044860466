import React, { useState, useRef, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './ActivationArea.css';
import phoneIcon from '../assets/phone-icon.svg';
import textIcon from '../assets/text-icon.svg';
import instagramIcon from '../assets/instagram-icon.svg';
import linkIcon from '../assets/link-icon.svg';
import Lottie from 'lottie-react';
import qrFrameAnimation from '../assets/qr-code.json';
import { useParams } from 'react-router-dom';
import texts from '../texts';
import Captcha from './Captcha';

const actionsList = ['PhoneNumber', 'TextToRead', 'InstagramID', 'Link'];

const ActivationArea = () => {
  const { link_id } = useParams();
  const [isPublic, setIsPublic] = useState(false);
  const [selectedAction, setSelectedAction] = useState(actionsList[0]);
  const [inputValue, setInputValue] = useState('');
  const [draftValue, setDraftValue] = useState('');
  const [language, setLanguage] = useState('farsi');
  const [captchaToken, setCaptchaToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonText, setButtonText] = useState(texts.activationButton[language]);
  const [disableActions, setDisableActions] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(true);

  const inputRef = useRef(null);
  const textareaRef = useRef(null);

  // Onboarding action transition
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isOnboarding) return;

      setSelectedAction((prevAction) => {
        const currentIndex = actionsList.indexOf(prevAction);
        const nextIndex = (currentIndex + 1) % actionsList.length;
        return actionsList[nextIndex];
      });
    }, 2500); // Change action every 1.5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [isOnboarding]);

  // Function to handle user interaction
  const handleUserInteraction = () => {
    if (isOnboarding) {
      setIsOnboarding(false); // Stop onboarding on user interaction
    }
  };

  const handleActionSelect = (action) => {
    if (!disableActions) {
      setSelectedAction(action);
      resetForm();
      setIsOnboarding(false); // Stop onboarding when an action is selected

      // Focus on the input field or textarea when action is selected
      if (action === 'TextToRead' && textareaRef.current) {
        textareaRef.current.focus();
      } else if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  // Set up event listener for user interaction
  useEffect(() => {
    if (isOnboarding) {
      window.addEventListener('click', handleUserInteraction);
      window.addEventListener('touchstart', handleUserInteraction); // For mobile touch events

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('click', handleUserInteraction);
        window.removeEventListener('touchstart', handleUserInteraction);
      };
    }
  }, [isOnboarding]);

  const resetForm = () => {
    setInputValue('');
    setDraftValue('');
    setSuccessMessage('');
    setErrorMessage('');
    setButtonText(texts.activationButton[language]);
  };

  const handleInputChange = (e) => {
    let input = e.target.value;
  
    // For Instagram ID and Link, prevent spaces and enforce English keyboard only
if (selectedAction === 'InstagramID' || selectedAction === 'Link') {
  // Remove spaces
  input = input.replace(/\s/g, '');

  // Ensure the input is only in English and allows URL characters for links
  const isLink = selectedAction === 'Link';
  const regex = isLink 
    ? /^[A-Za-z0-9._~:\/?#\[\]@!$&'()*+,;=%-]*$/ // URL characters (note the escaped brackets)
    : /^[A-Za-z0-9_@.]*$/; // Instagram ID characters

  if (!regex.test(input)) {
    return; // Do nothing if non-English characters are detected
  }
}

  
    // For Instagram ID, allow the user to enter manually without forcing the '@'
    if (selectedAction === 'InstagramID') {
      if (input.startsWith('@')) {
        input = input.substring(1); // Remove the '@' from the start
      }
    }
    setInputValue(input);
    setDraftValue(input);

    if (isInputValid(input)) {
      setDisableActions(true);
    } else {
      setDisableActions(false);
    }

    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const isInputValid = (input = inputValue) => {
    let valid = false;
    if (selectedAction === 'PhoneNumber') {
      // Validating the input to match 09123456789
      valid = /^09\d{9}$/.test(input);
    } else {
      valid = input.trim() !== ''; // Allow spaces but ensure the input is not just whitespace
    }
    return valid;
  };

  const handleCaptchaSuccess = (token) => {
    setCaptchaToken(token);
    setButtonText("بزن بریم!");
  };

  const handleActivation = async () => {
    const trimmedInput = selectedAction === 'TextToRead' ? inputValue : inputValue.trim(); // Keep textarea value intact

    if (!isInputValid(trimmedInput) || !captchaToken) return;

    const payload = {
      action: selectedAction,
      input: selectedAction === 'InstagramID' ? trimmedInput.replace(/^@/, '') : trimmedInput, // Remove the '@' for Instagram ID
      token: captchaToken,
      isPublic: isPublic,
    };

    try {
      setLoading(true);
      setButtonText("در حال فعالسازی...");
      const response = await fetch(`https://d1worker.sapienssimple.workers.dev/activate/${link_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.success) {
        setSuccessMessage(texts.activationSuccess[language]);
        setButtonText("با موفقیت فعال شد!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        setErrorMessage(texts.activationFailure[language]);
        setButtonText(texts.activationButton[language]);
      }
    } catch (error) {
      setErrorMessage(texts.errorMessage[language]);
      setButtonText(texts.activationButton[language]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, []);

  return (
    <div className='activation-area'>
      <div className='action-icons'>
        {actionsList.map((action, idx) => (
          <button 
            key={action} // Use action as the key
            className={`action ${selectedAction === action ? 'selected' : ''}`} 
            onClick={() => handleActionSelect(action)} 
            disabled={selectedAction !== action && disableActions} // Disable only unselected actions
          >
            <img 
              src={[phoneIcon, textIcon, instagramIcon, linkIcon][idx]} 
              alt={texts.actions[action][language]} 
              style={{ width: '20px', height: '20px' }} 
            />
          </button>
        ))}
      </div>
      <div className='qr-code-container'>
        <QRCodeSVG value={`qrmy.ir/${link_id}`} size={60} className="qr-code" />
        <Lottie animationData={qrFrameAnimation} loop={true} />
      </div>
      <h2 className={`action-explanation ${isOnboarding ? 'fade-in' : 'fade-out'}`}>
        {texts.actions[selectedAction][language]}
      </h2>
      <div className='input-container'>
        {selectedAction === 'TextToRead' ? (
          <textarea
            ref={textareaRef}
            className='input-field textarea'
            placeholder={texts.placeholders[selectedAction][language]}
            value={inputValue}
            onChange={handleInputChange}
            rows="1"  // Default height to 1 row
            style={{
              textAlign: 'right',
              direction: 'rtl',
              overflow: 'hidden',
              resize: 'none', // Prevent resizing
            }}
            onInput={adjustTextareaHeight} // Adjust height on input
          />
        ) : (
          <input
            ref={inputRef} // Attach the ref to the input field
            type={selectedAction === 'PhoneNumber' ? 'tel' : 'text'} // Change type to 'tel' for PhoneNumber
            className='input-field'
            placeholder={texts.placeholders[selectedAction][language]}
            value={inputValue}
            onChange={handleInputChange}
            style={{ textAlign: 'left', direction: 'ltr' }} // Set LTR for Instagram and PhoneNumber
          />
        )}
      </div>

      <div className='toggle-public'>
        <span className='toggle-text'>{isPublic ? texts.toggle.public[language] : texts.toggle.private[language]}</span>
        <span className='toggle-explanation'>{isPublic ? texts.toggle.toggleExplanationPublic[language] : texts.toggle.toggleExplanationPrivate[language]}</span>
        <input type='checkbox' checked={isPublic} onChange={() => {
          setIsPublic(!isPublic);
        }} />
      </div>

      <Captcha onSuccess={handleCaptchaSuccess} />

      <button className='activation-button' onClick={handleActivation} disabled={!isInputValid() || loading}>
        {loading ? <span className='loading'>در حال بارگذاری...</span> : buttonText}
      </button>

      {successMessage && <p className='success-message'>{successMessage}</p>}
      {errorMessage && <p className='error-message'>{errorMessage}</p>}
    </div>
  );
};

export default ActivationArea;
